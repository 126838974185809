<template>
    <div class="qa">
        <div class="list-top">
            <div class="titlebox">{{title}}</div>
            <div class="searchbar">
                <div class="input">
                    <div class="icon">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/search.png)"></div>
                        </div>
                    </div>
                    <input type="text" maxlength="30" placeholder="请输入您要搜索的内容" v-model="search">
                </div>
                <button class="btn" @click="getData">搜索</button>
            </div>
        </div>
        <div class="itemlist">
            <div class="item" v-for="(item, index) in list" :key="index" @click="qaShow(index)">
                <div class="quesion">
                    <div class="txt">Q：{{item.title}}</div>
                    <div class="arrow" :class="{active: item.answerShow}">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/arrow.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="answer" :class="{active: item.answerShow}">A：{{item.content.replace("<p>","").replace("</p>","")}}</div>
            </div>
        </div>
        <div class="empty" v-if="message">{{ message }}</div>
        <div class="fenye" v-if="list.length > 0">
            <el-pagination background layout="prev, pager, next" :page-size="10" :total="allCount" :current-page="page"
                @current-change="handlepage" />
        </div>
    </div>
</template>
  
<script>
import { getQA } from "@/js/request";
import { getInfo } from "@/js/request";

export default {
    name: 'Qa',
    components: {

    },
    props: ['title','cate','cateClick'],
    data() {
        return {
            allCount: 0,//总页数
            search: '',
            list: [],
            page: 1,
            baseUrl: '',
            message: '',
            cateValue: 0,
        }
    },
    watch: {
        cate: {
            handler: function (newValue) {
                this.cateValue = newValue;
                this.page=1;
                this.getData();
            }
        },
    },
    methods: {
        goto(adress, param) {
            this.$router.push({ path: adress, query: param });
        },
        handlepage(e) {
            this.page = e;
            this.getData();
        },
        getData() {
            this.message = '';
            getQA(this.page, this.cateValue, this.search).then(res => {
                this.allCount = JSON.parse(res.headers['x-pagination']).totalCount;
                this.list = res.data;
                this.baseUrl = res.config.baseURL;
            }).catch(error => {
                if (error.response) {
                    this.list = [];
                    this.message = error.response.data.message;
                }
            });
        },
        qaShow(index) {
            if(this.list[index].answerShow) {
                this.list[index].answerShow = !this.list[index].answerShow;
            } else this.list[index].answerShow = true;
        },
    },
    mounted() {
        this.$root.showRight();
        if(this.$route.query.id) {
            getInfo(this.$route.query.id).then(res => {
                this.list = [];
                this.list.push(res.data);
                this.list[0].answerShow = true;                
            });
        } else {
            this.getData();
        }
    }
}
</script>
  
<style lang="scss">
.qa {
    @include sys-boxsize(100%, auto, 20px, #fff);
    padding: 40px 60px;
    box-sizing: border-box;
    box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
    .list-top {
        @include sys-flex(space-between, row);
        .titlebox {
            @include sys-text-hide(1);
            @include sys-font(30px, #000, bold);
            line-height: 50px;
            flex: 1;
        }
        .searchbar {
            flex: none;
            display: flex;
            align-items: center;
            .input {
                @include sys-boxsize(360px, 50px, 25px, #F6F6F6);
                display: flex;
                align-items: center;
                margin-right: 10px;
                padding: 0 20px;
                box-sizing: border-box;
                .icon {
                    @include sys-picbox(20px, 20px);
                    margin-right: 10px;
                    flex: none;
                }
                input {
                    @include sys-font(20px, #333);
                    flex: 1;
                    height: 100%;
                    background-color: transparent;
                }
            }
            .btn {
                @include sys-text-hide(1);
                @include sys-radius-btn(120px, 50px, $acolor);
                @include sys-font(20px, #fff);
                cursor: pointer;
            }
        }
        
    }
    .itemlist {
        width: 100%;
        padding: 40px 0;
        .item {
            @include sys-boxsize(100%, auto, 20px, #fff);
            min-height: 112px;
            border: 1px solid #C5D3FF;
            margin-bottom: 20px;
            padding: 32px 40px;
            box-sizing: border-box;
            transition: all .6s;
            &:last-child {
                margin-bottom: 0;
            }
            .quesion {
                @include sys-flex(space-between, row);
                .txt {
                    @include sys-text-hide(1);
                    @include sys-font(24px, #000, bold);
                    flex: 1;
                }
                .arrow {
                    @include sys-picbox(32px, 32px);
                    padding: 10px;
                    margin-right: -10px;
                    flex: none;
                    transition: transform .6s;
                    &.active {
                        transform: rotate(90deg);
                    }
                }
            }
            .answer {
                @include sys-font(16px, #000);
                line-height: 30px;
                margin-top: 6px;
                letter-spacing: 1px;
                overflow: hidden;
                transition: height .6s;
                height: 0;
                &.active {
                    height: auto;
                }
            }
        }
    }
}
</style>